import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const techDashboardPlugin = createPlugin({
  id: 'tech-dashboard',
  routes: {
    root: rootRouteRef,
  },
});

export const TechDashboardPage = techDashboardPlugin.provide(
  createRoutableExtension({
    name: 'TechDashboardPage',
    component: () =>
      import('./components/TechDashboardComponent').then(m => m.TechDashboardComponent),
    mountPoint: rootRouteRef,
  }),
);
