import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const onepagePlugin = createPlugin({
  id: 'onepage',
  routes: {
    root: rootRouteRef,
  },
});

export const OnepagePage = onepagePlugin.provide(
  createRoutableExtension({
    name: 'OnepagePage',
    component: () =>
      import('./components/OnePageComponent').then(m => m.OnePage),
    mountPoint: rootRouteRef,
  }),
);
