// packages/app/src/components/HomePage.tsx
import React from 'react';
import {
  Content,
  Page,
} from '@backstage/core-components';
import {
  HomePageCompanyLogo,
  HomePageToolkit,
  HomePageStarredEntities,
} from '@backstage/plugin-home';
import './HomePage.css';
import CategoryIcon from '@material-ui/icons/Category';
import { HomePageSearchBar } from '@backstage/plugin-search';
import MuiDocsIcon from '@material-ui/icons/Description';


const HomePage = () => {
  return (
    <Page themeId="home">
      <Content>
        {/* Logo da Empresa */}
        <HomePageCompanyLogo logo={<img className="homeLogo" src="/ipiranga-logo-5.png" alt="Minha Empresa" />} />
        <div style={{ marginBottom: '20px' }}>
          <HomePageSearchBar placeholder="Pesquisar no catálogo..." />
        </div>
        {/* Bloco de Ferramentas */}
        <HomePageToolkit
          title="Ferramentas"
          tools={[
            {
                url: '/catalog', label: 'Catálogo',
                icon: <CategoryIcon />
            },
            {
                url: '/docs', label: 'Documentação',
                icon: <MuiDocsIcon />
            },
          ]}
        />

        {/* Bloco de Favoritos */}
        <HomePageStarredEntities
          title="Favoritos"
        />
        
        {/* Outros Blocos Customizados */}
      </Content>
    </Page>
  );
};

export default HomePage;